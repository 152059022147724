@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}




/* input {
  border-image-source: linear-gradient(to right, #00C853, #ffff);
  border-width: 2pt;
  border-image-slice: 1;
  border-radius: 16px;
  border-image-radius: 16px;

} */


input,textarea {
  padding: 0.5rem;
  border: double 3px transparent;
  border-radius: 6px;
  background-image: linear-gradient(#C4C4C4, white), 
                    linear-gradient(to right, #00C853, white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.grad {
  position: relative;
  padding: 20px 30px;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
}

.grad::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 10px; /* control the border thickness */
  background: linear-gradient(45deg, red, blue);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  pointer-events: none;
}